<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'InformationUser',
};
</script>
<style lang="scss" scoped>
.page {
  width: 100%;
  min-height: 100%;
}
</style>
